import React from "react"
import classNames from 'classnames'

import Layout from '../components/layout/layout'
import PoemHeader from '../components/poemHeader/poemHeader'

import styles from "./attribution.module.scss"

function Attribution() {
  const variant = 'light'
  return (
    <Layout
      className={ classNames(styles.attribution, styles[variant]) }
    >
      <PoemHeader
        className={ classNames(styles.poemHeader, styles[variant]) }
        title={ `read the full poem\non <strong>versus-verses.com</strong>\n\n<em>{ link in description }</em>` }
        titleFontSize={ 0.7 }
        titleClassName="h2"
        titleTag="h2"
        variant={ variant }
      />
    </Layout>
  )
}

export default Attribution
